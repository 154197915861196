<template>
  <div>
    <a-card size="small" :bordered="false" style="height: 100%" :bodyStyle="{ height: '200px' }">
      <template slot="title">{{ $t("库存趋势图") }}</template>
      <template slot="extra">
        <a-range-picker
          v-model="dateRange"
          size="small"
          :allowClear="false"
          style="width: 200px; margin: -4px 0"
          @change="queryData"
        />
      </template>

      <div id="inventoryStatsChart" style="height: 100%"></div>
    </a-card>
  </div>
</template>

<script>
import { homeStockTrend } from "@/api/data";
import { Line } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      dataChart: null,
      dataItems: [],

      dateRange: [moment().add(-7, "days").startOf("day"), moment().startOf("day")],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    queryData() {
      const startDate = this.dateRange[0].format("YYYY-MM-DD");
      const endDate = this.dateRange[1].clone().add(1, "days").format("YYYY-MM-DD");
      const diffDays = this.dateRange[1].diff(this.dateRange[0], "days") + 1;

      homeStockTrend({ warehouse: this.currentWarehouse, start_date: startDate, end_date: endDate }).then((data) => {
        const dataItems = [];
        for (let index = 0; index < diffDays; index++) {
          const date = this.dateRange[0].clone().add(index, "days").format("YYYY-MM-DD");
          const dataItem = { date, quantity: 0 };
          dataItems.push(dataItem);

          for (let item of data.results) {
            if (moment(item.date).format("YYYY-MM-DD") == date) {
              dataItem.quantity = item.quantity;
              break;
            }
          }
        }

        this.dataItems = dataItems;
        this.dataChart.changeData(this.dataItems);
      });
    },
  },
  mounted() {
    this.dataChart = new Line("inventoryStatsChart", {
      data: [],
      autoFit: true,
      xField: "date",
      yField: "quantity",
      label: {},
      meta: { date: { alias: this.$t("日期") }, quantity: { alias: this.$t("库存数量") } },
    });
    this.dataChart.render();

    this.queryData();
  },
};
</script>
